import React, { useContext, useState } from 'react';
import Login from '../components/Login';
import { RootActionsContext } from '../lib/contexts';
import { _ } from '../lib/l18n';
import { isEmailValid } from '../lib/validation';

const LoginContainer: React.FC<{ defaultEmail?: string }> = ({ defaultEmail }) => {
  const [email, setEmail] = useState(defaultEmail || '');
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const { sendAuthenticationEmail } = useContext(RootActionsContext);

  const isValid = isEmailValid(email);
  const canEdit = !isLoading && !success;
  const canSubmit = !isLoading && isValid && canEdit;

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setError(undefined);
  };

  const handleSubmit = async () => {
    if (!canSubmit) return;
    setError(undefined);
    setLoading(true);

    try {
      await sendAuthenticationEmail(email);
    } catch (err: Error | any) {
      setError(err.message);
      setLoading(false);
      return;
    }

    setSuccess(_('authLinkSendToEmail'));
    setLoading(false);
  };

  return (
    <Login
      email={email}
      onEmailChange={handleEmailChange}
      onSubmit={handleSubmit}
      loading={isLoading}
      canEdit={canEdit}
      canSubmit={canSubmit}
      errorMessage={error}
      successMessage={success}
    />
  );
};

export default LoginContainer;
