import { LeaderboardFilter, RedemptionFilter } from './types.local';

const isStaging = (host: string) => {
  return (
    host.match(/^.*\.dev\.motrain\.com$/) || host.match(/^.*\.dev\.motrainapp\.com$/) || host.match(/^.*\.dev\.incentli\.com$/)
  );
};

export const getApiRootUrl = () => {
  let apiUrl: string | undefined = process.env.REACT_APP_API_URL || undefined;
  if (!apiUrl) {
    const host = window.location.hostname;
    apiUrl = 'https://api.incentli.com';
    if (host === 'localhost') {
      apiUrl = 'http://localhost:9000';
    } else if (isStaging(host)) {
      apiUrl = 'https://api.dev.incentli.com';
    } else if (host.match(/^.*\.eu\.(motrain|motrainapp|incentli)\.com$/)) {
      apiUrl = 'https://api.eu.incentli.com';
    }
  }
  return apiUrl;
};

export const getAbsoluteAuthUrl = () => {
  let authUrl: string | undefined = process.env.REACT_APP_STORE_AUTH_URL || undefined;
  if (authUrl) return authUrl;

  const host = window.location.hostname;
  authUrl = 'https://auth.incentli.com';
  if (host === 'localhost') {
    authUrl = 'http://localhost:9200';
  } else if (isStaging(host)) {
    authUrl = 'https://auth.dev.incentli.com';
  } else if (host.match(/^.*\.eu\.(motrain|motrainapp|incentli)\.com$/)) {
    authUrl = 'https://auth.eu.incentli.com';
  }
  return authUrl;
};

export const getAbsoluteStoreUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

// Route URLs and paths.
export const storeUrl = '/store';
export const purchaseBulkRedeemUrl = '/bulk-redeem';
export const purchaseBulkRedeemThankYouUrl = '/bulk-redeem/thank-you';
export const purchaseRedeemPath = '/purchases/:id/redeem';
export const purchaseThankYouPath = '/purchases/:id/thank-you';
export const redemptionsUrl = '/redemptions';
export const redemptionsUrlPaths = ['/redemptions/:filter', redemptionsUrl];
export const leaderboardUrl = '/leaderboards';
export const leaderboardUrlPaths = ['/leaderboards/:filter', leaderboardUrl];
export const prefsUrl = '/prefs';
export const profileUrl = '/profile';
export const bravaUrl = '/brava';
export const cartUrl = '/cart';
export const infoUrl = '/info';
export const logoutUrl = '/logout';
export const productUrlPath = '/product/:id';

// Dynamic route URLs.
export const getLeaderboardFilterUrl = (filter: LeaderboardFilter) => {
  if (filter === LeaderboardFilter.Solo) {
    return '/leaderboards';
  }
  return `/leaderboards/${filter}`;
};
export const getProductUrl = (productId: string) => `/product/${productId}`;
export const getPurchaseRedeemUrl = (purchaseId: string) => `/purchases/${purchaseId}/redeem`;
export const getPurchaseThankYouUrl = (purchaseId: string) => `/purchases/${purchaseId}/thank-you`;
export const getRedemptionsFilterUrl = (filter: RedemptionFilter) => `/redemptions/${filter}`;
