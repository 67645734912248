import React from 'react';

import MinusIcon from '../assets/icons/minus.svg';
import MinusHoverIcon from '../assets/icons/minus-hover.svg';
import PlusIcon from '../assets/icons/plus.svg';
import PlusHoverIcon from '../assets/icons/plus-hover.svg';
import { _ } from '../lib/l18n';
import { classNames } from '../lib/utils';

const NumberSelector: React.FC<{
  id?: string;
  value: number;
  onChange: (n: number) => void;
  disabled?: boolean;
  width?: string;
  height?: string;
  wrapperClassName?: string;
  fieldClassName?: string;
}> = ({ id, value, onChange, disabled = false, width = '118px', height = '40px', wrapperClassName, fieldClassName }) => {
  const handleQtyChange = (qty: number) => {
    if (disabled) return;
    onChange(Math.max(0, qty));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Math.max(0, parseInt(e.target.value, 10) || 0));
  };
  let classes = 'rounded border-silver-4 flex grow border p2';
  let btnClasses = 'group outline-none px-3 focus:outline-none';
  if (disabled) {
    classes += ' bg-silver-3 border-none';
    btnClasses = btnClasses.replace('group', '');
  }
  return (
    <div className={classNames('flex', wrapperClassName)}>
      <div className={classes} style={{ width, height }}>
        <div className="shrink-0 flex justify-center">
          <button className={btnClasses} onClick={(e) => handleQtyChange(value - 1)} type="button" disabled={value <= 0}>
            <img src={MinusIcon} alt={_('removeOne')} className="group-hover:hidden group-focus:hidden" />
            <img src={MinusHoverIcon} alt={_('removeOne')} className="hidden group-hover:block group-focus:block" />
          </button>
        </div>
        <div className="flex flex-1 justify-center">
          <input
            id={id}
            type="text"
            value={value.toFixed(0)}
            onChange={handleChange}
            className={classNames('outline-none text-center w-full', disabled ? 'bg-silver-3' : '', fieldClassName)}
            disabled={disabled}
          />
        </div>
        <div className="shrink-0 flex justify-center">
          <button className={btnClasses} onClick={(e) => handleQtyChange(value + 1)} type="button">
            <img src={PlusIcon} alt={_('addOne')} className="group-hover:hidden group-focus:hidden" />
            <img src={PlusHoverIcon} alt={_('addOne')} className="hidden group-hover:block group-focus:block" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberSelector;
