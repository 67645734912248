import React from 'react';
import { _ } from '../lib/l18n';
import { RecentActivityItem } from '../lib/types.local';
import Activity, { ActivityItem, ActivityItemLst } from './Activity';
import { ProfilePageLayout } from './layouts/ProfilePageLayout';

const RecentActivity: React.FC<{ items: RecentActivityItem[]; loading?: boolean }> = ({ items, loading = true }) => {
  return (
    <ProfilePageLayout>
      {!loading ? (
        <Activity>
          <ActivityItemLst>
            {items.map((item) => {
              const key = `${item.date.getTime()}-${item.amount}`;
              return (
                <ActivityItem
                  key={key}
                  date={item.date.toLocaleDateString()}
                  amount={item.amount}
                  label={_('awardType')}
                  value={item.description}
                ></ActivityItem>
              );
            })}
          </ActivityItemLst>
        </Activity>
      ) : null}
    </ProfilePageLayout>
  );
};

export default RecentActivity;
