import React from 'react';
import { useIsEmbedded } from '../../lib/hooks';
import { _ } from '../../lib/l18n';
import { logoutUrl, prefsUrl, profileUrl } from '../../lib/urls';
import PageWithSideMenu, { SideMenuItem } from './PageWithSideMenu';
import { HasBravaEnabled } from '../utils/HasBravaEnabled';

export const ProfilePageLayout = ({ children }: { children: React.ReactNode }) => {
  const isEmbedded = useIsEmbedded();
  return (
    <PageWithSideMenu
      menuTitle={_('profile')}
      renderMenuItems={() => (
        <>
          <SideMenuItem to={profileUrl}>{_('recentActivity')}</SideMenuItem>
          <HasBravaEnabled>
            <SideMenuItem to={prefsUrl}>{_('preferences')}</SideMenuItem>
          </HasBravaEnabled>
          {!isEmbedded ? <SideMenuItem to={logoutUrl}>{_('logout')}</SideMenuItem> : null}
        </>
      )}
    >
      {children}
    </PageWithSideMenu>
  );
};
