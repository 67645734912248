import { format, formatISO, fromUnixTime } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { Redirect } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { formatTimeAgoShortDuration } from '../lib/date';
import { useRefresher, useRepository } from '../lib/hooks';
import { _, getBravaFeedReasonLabel } from '../lib/l18n';
import { BravaFeedEntry, BravaFeedResult, User } from '../lib/types';
import { classNames } from '../lib/utils';
import { useBrandingStore, useIsBravaEnabled, useUserStore } from '../state/store';
import { BravaCurrencyIcon } from './BravaCurrencyIcon';
import Button from './Button';
import ContentContainer from './layouts/ContentContainer';
import NumberSelector from './NumberSelector';
import PillMenu, { PillMenuItem } from './PillMenu';
import { Placeholder, PlaceholderList } from './Placeholder';
import Select from './Select';
import { getColorForReason } from '../lib/brava';

const useCachedQueryUpdater = () => {
  const queryClient = useQueryClient();
  const fn = useCallback(
    (userId: string, entryId: string, updateFn: (entry: BravaFeedEntry) => BravaFeedEntry) => {
      const keys = [
        ['brava-feed', undefined],
        ['brava-feed', userId],
      ];
      keys.forEach((key) => {
        // The argument `old` of setQueryData updated function is undefined, so we getQueryData ourselves.
        // That may be because of staleTime or cacheTime, it's hard to tell.
        const oldData = queryClient.getQueryData<InfiniteData<BravaFeedResult>>(key);
        if (!oldData) {
          queryClient.invalidateQueries(key);
          return;
        }
        queryClient.setQueryData(key, () => {
          const newData = {
            ...oldData,
            pages: oldData.pages.map((page: any) => ({
              ...page,
              items: page.items.map((item: BravaFeedEntry) => {
                if (item.id === entryId) {
                  return updateFn(item);
                }
                return item;
              }),
            })),
          };
          return newData;
        });
      });
    },
    [queryClient]
  );
  return fn;
};

const useBravaFeed = (forYou: boolean) => {
  const { id } = useUserStore();
  const repo = useRepository();
  const userId = forYou ? id : undefined;

  return useInfiniteQuery(
    ['brava-feed', userId],
    async ({ pageParam }) => {
      const res = await repo.getBravaFeed(userId, pageParam);
      return res;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );
};

const useCommentMutation = ({ onSuccess }: { onSuccess: () => void }) => {
  const repo = useRepository();
  const queryClient = useQueryClient();
  const { id: userId } = useUserStore();
  const updateCachedEntry = useCachedQueryUpdater();
  return useMutation(
    async ({ entry, comment, amount }: { entry: BravaFeedEntry; comment: string; amount: number | undefined }) => {
      if (!comment.trim().length) {
        return Promise.reject();
      }
      return repo.postBravaFeedComment(entry.id, comment, amount);
    },
    {
      onSuccess(data, { entry }, context) {
        onSuccess();
        queryClient.invalidateQueries(['me']);
        updateCachedEntry(userId, entry.id, (item) => {
          return {
            ...item,
            comments: item.comments + 1,
            comments_top: [...(item.comments_top ?? []), data].sort((a, b) => b.created_on - a.created_on),
          };
        });
      },
    }
  );
};

export const BravaPage = () => {
  const [filterForMe, setFilterForMe] = useState(false);
  const bravaEnabled = useIsBravaEnabled();
  if (!bravaEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer className="flex flex-col flex-grow">
      <div className="flex flex-col md:flex-row">
        <div className="md:mr-10 md:w-44 lg:w-64 lg:flex-width mb-6 hidden md:block">
          <Stats />
        </div>
        <div className="flex-1">
          <div className="mb-4 sm:mb-0">
            <PostWriter />
          </div>
          <div className="mb-4">
            <div className="inline-block">
              <PillMenu>
                <PillMenuItem onClick={() => setFilterForMe(false)} active={!filterForMe}>
                  {_('all')}
                </PillMenuItem>
                <PillMenuItem onClick={() => setFilterForMe(true)} active={filterForMe}>
                  {_('forYou')}
                </PillMenuItem>
              </PillMenu>
            </div>
          </div>
          <Feed forYou={filterForMe} />
        </div>
      </div>
    </ContentContainer>
  );
};

const Stats = () => {
  const userStore = useUserStore();
  const { brava } = userStore;

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-grey-5 rounded p-4 text-center flex flex-col items-center gap-2">
        <div>{_('leftToGive')}</div>
        <div className="flex gap-2 items-center leading-none">
          {brava.remaining === Infinity ? '∞' : brava.remaining} <BravaCurrencyIcon />
        </div>
      </div>
      <div className="bg-grey-5 rounded p-4 text-center flex flex-col items-center gap-2">
        <div>{_('givenThisMonth')}</div>
        <div className="flex gap-2 items-center leading-none">
          {brava.given} <BravaCurrencyIcon />
        </div>
      </div>
    </div>
  );
};

const Feed = ({ forYou }: { forYou: boolean }) => {
  const feed = useBravaFeed(forYou);
  if (feed.isLoading) {
    return (
      <div className="space-y-2">
        <PlaceholderList count={10}>
          <Placeholder className="rounded w-full h-24" />
        </PlaceholderList>
      </div>
    );
  }

  const hasAnyContent = (feed.data?.pages?.flatMap((p) => p.items)?.length ?? 0) > 0;
  if (!hasAnyContent) {
    return (
      <div className="py-12 text-lg px-4 justify-center flex flex-col text-center bg-grey-5 rounded">
        <div>{_('nothingHereYet')}</div>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {feed.data?.pages
        .flatMap((p) => p.items)
        .map((item) => {
          return <FeedEntry key={item.id} entry={item} />;
        })}
      {feed.hasNextPage ? (
        <div>
          <Button onClick={() => feed.fetchNextPage()} disabled={feed.isFetching}>
            {_('loadMore')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const BravaAmount = ({
  amount,
  currency,
  showIcon = true,
  iconClassName,
}: {
  amount: number;
  currency: 'coins' | 'tickets';
  showIcon?: boolean;
  iconClassName?: string;
}) => {
  return (
    <div className="gap-1 items-center inline-flex leading-none">
      {`+${amount}`}
      {showIcon ? <BravaCurrencyIcon currency={currency} className={iconClassName} /> : null}
    </div>
  );
};

const EntryLikeButton = ({ entry }: { entry: BravaFeedEntry }) => {
  const { id } = useUserStore();
  const repo = useRepository();
  const queryClient = useQueryClient();
  const isLiked = entry.likedby_players.includes(id);
  const updateCachedEntry = useCachedQueryUpdater();

  const mutation = useMutation(
    async ({ entryId, like }: { entryId: string; like: boolean }) => {
      if (like) {
        await repo.likeBravaFeedEntry(entryId);
      } else {
        await repo.unlikeBravaFeedEntry(entryId);
      }
    },
    {
      onMutate: async ({ entryId, like }) => {
        const previousData = queryClient.getQueryData<BravaFeedResult>('brava-feed');

        // Optimistically update the cache
        updateCachedEntry(id, entryId, (item) => ({
          ...item,
          likes: like ? item.likes + 1 : item.likes - 1,
          likedby_players: like ? [...item.likedby_players, id] : item.likedby_players.filter((playerId) => playerId !== id),
        }));

        // Return a context object with the snapshotted value
        return { previousData };
      },
      onError: (err, variables, context?: { previousData: BravaFeedResult | undefined }) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        if (context?.previousData) {
          queryClient.setQueryData('brava-feed', context.previousData);
        }
      },
    }
  );

  const handleClick = () => {
    mutation.mutate({ entryId: entry.id, like: !isLiked });
  };

  return (
    <FeedButton
      onClick={handleClick}
      disabled={mutation.isLoading}
      className={classNames(isLiked ? 'text-turquoise' : 'text-grey-90')}
    >
      <div className="grow text-center pt-0.5">{entry.likes}</div>
      <div className="leading-none">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
          <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
        </svg>
      </div>
    </FeedButton>
  );
};

const FeedButton = ({ className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <div>
      <button
        className={classNames(
          'h-full flex gap-2 rounded border border-silver-4 items-center px-2 py-1 min-w-20 hover:bg-grey-5 text-sm leading-none',
          className
        )}
        type="button"
        {...props}
      >
        {props.children}
      </button>
    </div>
  );
};

const FeedEntry = ({ entry }: { entry: BravaFeedEntry }) => {
  const [openComment, setOpenComments] = useState(false);
  const createdOnDate = fromUnixTime(entry.created_on);
  const {
    brava: { remaining },
  } = useUserStore();
  const branding = useBrandingStore();
  const { id: userId } = useUserStore();
  const hasCurrentCurrency = !entry.brava_currency || entry.brava_currency === branding.brava_currency;
  const handleOpenComment = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenComments(true);
  }, []);

  return (
    <div className="">
      <div className="flex flex-col p-2 gap-2 sm:p-0 sm:gap-0 sm:flex-row text-lg bg-grey-5 rounded-tr rounded-tl sm:items-center">
        <div className="grow sm:px-3 sm:py-1 font-semibold flex items-center gap-2">
          <div className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white text-grey-90">👏</div>
          <div>{entry.recipient_name}</div>
          {entry.private ? (
            <div>
              <span className="inline-block ml-2 bg-grey-dark-2 text-white text-xs px-2 py-1 rounded">{_('private')}</span>
            </div>
          ) : null}
        </div>
        <div className="flex grow sm:grow-0 sm:items-center">
          <div className="grow sm:grow-0 shrink-0 whitespace-nowrap sm:p-3">
            {entry.brava_total ? <BravaAmount amount={entry.brava_total} currency={entry.brava_currency ?? 'coins'} /> : null}
          </div>
          <div className="grow-0 shrink-0 whitespace-nowrap sm:pr-3">
            <FeedEntryReasonTag reason={entry.brava_reason} />
          </div>
        </div>
      </div>
      <div className="pt-4 p-3 border border-grey-5 border-t-0 rounded-br rounded-bl">
        <div className="flex flex-col sm:flex-row gap-3">
          {entry.author_id ? (
            <div className="shrink-0">
              <div
                className="inline-block bg-grey-5 rounded px-2 py-1 font-bold"
                title={entry.author_name || ''}
                style={{ maxWidth: '30ch' }}
              >
                {entry.author_name}
              </div>
            </div>
          ) : null}
          <div className={classNames('grow', !entry.brava_amount ? 'pt-1' : '')}>
            {entry.brava_amount ? (
              <div className="inline-block bg-grey-5 rounded px-2 py-1 font-bold mr-3">
                <BravaAmount amount={entry.brava_amount} currency={entry.brava_currency!} showIcon={false} />
              </div>
            ) : null}
            {entry.message}
          </div>
          <div className="shrink-0 grow-0 text-gray-700 pt-1">
            <FeedEntryTime date={createdOnDate} />
          </div>
        </div>
        {!entry.private ? (
          <>
            <div className="my-4 flex flex-col sm:flex-row gap-4 last:mb-0 sm:h-10">
              <div className="grow flex items-stretch">
                {!openComment ? (
                  <FeedButton onClick={handleOpenComment} className="h-full">
                    {_('addBrava')}
                  </FeedButton>
                ) : (
                  <div className="flex grow gap-1">
                    <div className="grow">
                      <EntryCommentWriter
                        entry={entry}
                        withBrava={hasCurrentCurrency}
                        bravaDisabled={!(entry.recipient_id !== userId && remaining > 0)}
                      />
                    </div>
                    <button
                      className="flex sm:items-center justify-center items-start"
                      type="button"
                      onClick={() => setOpenComments(false)}
                    >
                      <span className="sr-only">{_('close')}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              <div className="flex sm:justify-center items-stretch">
                <EntryLikeButton entry={entry} />
              </div>
            </div>
          </>
        ) : null}
        {entry.comments > 0 ? (
          <div className="mt-4 space-y-3">
            {(entry.comments_top ?? []).map((comment) => {
              return <EntryComment key={comment.id} entry={entry} comment={comment} />;
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const EntryComment = ({
  entry,
  comment,
}: {
  entry: BravaFeedEntry;
  comment: NonNullable<BravaFeedEntry['comments_top']>[number];
}) => {
  const createdOnDate = fromUnixTime(comment.created_on);

  return (
    <div className="text-sm flex gap-2">
      <div className="grow">
        <div className="inline-block bg-grey-5 rounded px-1.5 py-0.5 mr-2">{comment.author_name}</div>
        {comment.brava_amount ? (
          <div className="inline-block bg-grey-5 rounded px-1.5 py-0.5 mr-2">
            <BravaAmount amount={comment.brava_amount} currency={entry.brava_currency!} iconClassName="h-4.5" showIcon={false} />
          </div>
        ) : null}
        {comment.message}
      </div>
      <div className="pt-1 shrink-0 text-gray-700">
        <FeedEntryTime date={createdOnDate} />
      </div>
    </div>
  );
};

const EntryCommentWriter = ({
  entry,
  withBrava,
  bravaDisabled,
}: {
  entry: BravaFeedEntry;
  withBrava: boolean;
  bravaDisabled?: boolean;
}) => {
  const [comment, setComment] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>();
  const {
    id: userId,
    brava: { remaining },
  } = useUserStore();

  const mutation = useCommentMutation({
    onSuccess: () => {
      setComment('');
      setAmount(undefined);
    },
  });

  const canSubmit = Boolean(comment) && (!amount || (amount > 0 && amount <= remaining)) && !mutation.isLoading;
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!comment) {
      return;
    }
    mutation.mutate({ entry, comment, amount });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col sm:flex-row gap-1 text-sm">
        {withBrava ? (
          <div className="grow-0 whitespace-nowrap">
            <div className="items-center inline-flex gap-1">
              <div>
                <NumberSelector
                  value={amount ?? 0}
                  onChange={setAmount}
                  disabled={bravaDisabled}
                  width={'auto'}
                  fieldClassName="w-4ch"
                />
              </div>
              {/* <BravaCurrencyIcon currency={entry.brava_currency ?? null} /> */}
            </div>
          </div>
        ) : null}
        <div className="grow">
          <input
            type="text"
            placeholder={_('leaveACommentEllipsis')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            maxLength={280}
            className="h-full w-full rounded border-silver-4 border px-2 py-1"
          />
        </div>
        <div>
          <Button isSubmit disabled={!canSubmit} spin={mutation.isLoading} fillHeight>
            {_('bravaExcl')}
          </Button>
        </div>
      </div>
    </form>
  );
};

const FeedEntryTime = ({ date }: { date: Date }) => {
  useRefresher(30);
  return (
    <time title={format(date, 'PPPP pp')} dateTime={formatISO(date)}>
      {formatTimeAgoShortDuration(_, date)}
    </time>
  );
};

const FeedEntryReasonTag = ({ reason }: { reason: string }) => {
  const label = getBravaFeedReasonLabel(reason);
  const [bg, fg] = getColorForReason(reason);
  return (
    <div className="text-sm px-2 py-1 rounded" style={{ color: fg, backgroundColor: bg }}>
      {label}
    </div>
  );
};

type Recipient = Pick<User, 'id' | 'firstname' | 'lastname'>;

type Props = {
  id: string;
  value?: Recipient | null;
  onChange: (recipient?: Recipient | null) => void;
};

const RecipientSelect = (props: Props) => {
  const { id: userId } = useUserStore();
  const repo = useRepository();

  const recipientSearchLoader = useCallback(
    async (searchTerm: string) => {
      return (await repo.searchPlayers({ term: searchTerm })).filter((u) => u.id !== userId);
    },
    [repo, userId]
  );

  return (
    <AsyncSelect<Recipient>
      className="brava-recipient react-select"
      classNamePrefix="react-select"
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
      placeholder={<span>{_('selectARecipient')}</span>}
      inputId={props.id}
      isSearchable={true}
      onChange={props.onChange}
      noOptionsMessage={({ inputValue }) => {
        if (!inputValue) {
          return _('startTypingToSearch');
        }
        return _('noResultsEllipsis');
      }}
      loadOptions={recipientSearchLoader}
      value={props.value}
    />
  );
};

const PostWriter = () => {
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState('');
  const [recipient, setRecipient] = useState<Recipient | undefined | null>(null);
  const [amount, setAmount] = useState<number>(0);

  const {
    brava: { remaining },
  } = useUserStore();

  const repo = useRepository();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async () => {
      if (!message.trim() || !reason || !recipient?.id) {
        return Promise.reject(new Error('Required fields missing'));
      }
      return repo.postBravaFeedEntry({
        message,
        brava_reason: reason,
        recipient_id: recipient.id,
        brava_amount: amount || undefined,
      });
    },
    {
      onSuccess: () => {
        setMessage('');
        setReason('');
        setRecipient(null);
        setAmount(0);
        queryClient.invalidateQueries('brava-feed');
        queryClient.invalidateQueries(['me']);
      },
    }
  );

  const canSubmit =
    Boolean(message && reason && recipient?.id) && (!amount || (amount > 0 && amount <= remaining)) && !mutation.isLoading;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  const reasonOptions = [
    { value: 'celebrations', label: getBravaFeedReasonLabel('celebrations') },
    { value: 'communityImpact', label: getBravaFeedReasonLabel('communityImpact') },
    { value: 'greatJob', label: getBravaFeedReasonLabel('greatJob') },
    { value: 'personalDevelopment', label: getBravaFeedReasonLabel('personalDevelopment') },
    { value: 'thankYou', label: getBravaFeedReasonLabel('thankYou') },
    { value: 'welcome', label: getBravaFeedReasonLabel('welcome') },
    { value: 'wellnessAchievements', label: getBravaFeedReasonLabel('wellnessAchievements') },
    { value: 'workLifeBalanceSuccess', label: getBravaFeedReasonLabel('workLifeBalanceSuccess') },
  ];

  return (
    <form onSubmit={handleSubmit} className="brava-postwriter rounded">
      <div className="grid lg:grid-cols-3 gap-1 mb-1">
        <div>
          <label htmlFor="postwriter-recipient" className="sr-only block mb-2">
            {_('recipient')}
          </label>
          <RecipientSelect id="postwriter-recipient" value={recipient} onChange={setRecipient} />
        </div>
        <div>
          <label htmlFor="postwriter-reason" className="sr-only block mb-2">
            {_('reason')}
          </label>
          <Select
            id="postwriter-reason"
            value={reasonOptions.find((opt) => opt.value === reason)}
            onChange={(opt) => setReason(opt?.value || '')}
            options={reasonOptions}
          />
        </div>
        <div>
          <label className="sr-only block mb-2" htmlFor="postwriter-amount">
            {_('amount')}
          </label>
          <div className="flex items-center gap-2 h-full">
            <NumberSelector
              id="postwriter-amount"
              value={amount}
              onChange={setAmount}
              width={'auto'}
              height="auto"
              wrapperClassName="grow lg:h-full h-10"
              disabled={!remaining}
            />
            <BravaCurrencyIcon />
          </div>
        </div>
      </div>
      <div className="mb-1 leading-none">
        <label className="sr-only block mb-2" htmlFor="postwriter-message">
          {_('message')}
        </label>
        <textarea
          id="postwriter-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="h-full w-full rounded border-silver-4 border px-2 py-1 leading-normal"
          rows={3}
          maxLength={280}
          placeholder={_('leaveACommentEllipsis')}
        />
      </div>
      <div className="flex justify-end">
        <div>
          <Button primary isSubmit disabled={!canSubmit} spin={mutation.isLoading} fillHeight>
            {_('post')}
          </Button>
        </div>
      </div>
    </form>
  );
};
