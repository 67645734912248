import React from 'react';

import { useBrandingStore } from '../state/store';
import { TicketsIcon } from './TicketsIcon';
import CoinsIcon from './CoinsIcon';

export const BravaCurrencyIcon: React.FC<{ currency?: 'coins' | 'tickets' | null; plain?: boolean; className?: string }> = ({
  className,
  currency,
  plain = false,
}) => {
  const { brava_currency } = useBrandingStore();
  const c = currency ?? brava_currency;
  if (c === 'tickets') {
    return <TicketsIcon className={className} />;
  }
  return <CoinsIcon className={className} plain={plain} />;
};
