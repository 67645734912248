import React from 'react';
import Spinner from './Spinner';

const Button: React.FC<{
  isSubmit?: boolean;
  primary?: boolean;
  disabled?: boolean;
  spin?: boolean;
  fillHeight?: boolean;
  onClick?: () => void;
}> = ({ isSubmit = false, primary, disabled, spin, fillHeight, onClick, children }) => {
  let classes =
    'p-3 w-full rounded border border-turquoise uppercase text-gunmetal text-xs leading-none hover:shadow flex items-center justify-center text-center gap-2';
  if (disabled) {
    classes += ' bg-grey-20 hover:shadow-none text-white';
    classes = classes.replace(' border-turquoise ', ' border-disabled ');
  } else if (primary) {
    classes += ' bg-turquoise text-white hover:bg-turquoise-50';
  }

  if (fillHeight) {
    classes += ' h-full';
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !isSubmit && e.preventDefault();
    onClick && onClick();
  };
  return (
    <button className={classes} disabled={disabled} onClick={handleClick} type={isSubmit ? 'submit' : 'button'}>
      {spin ? <Spinner /> : null}
      <div className="flex">{children}</div>
    </button>
  );
};

export default Button;
