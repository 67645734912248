import { Switch } from '@headlessui/react';
import { ProfilePageLayout } from './layouts/ProfilePageLayout';
import { _ } from '../lib/l18n';
import { classNames } from '../lib/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useRepository } from '../lib/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useUserStore } from '../state/store';

const Toggle: React.FC<{ enabled?: boolean; onChange: (enabled: boolean) => void; label: string }> = ({
  enabled = false,
  onChange,
  label,
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-green-500' : 'bg-silver',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-4">
        <span className="">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

const Divider: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`-mx-4 md:mx-0 my-6 border-b border-blue-cloudy ${className || ''}`}></div>
);

const usePrefMutation = (prefName: string, initialValue: boolean) => {
  const repo = useRepository();
  const [value, setValue] = useState(initialValue);
  const queryClient = useQueryClient();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const mutation = useMutation(
    async (value: boolean) => {
      setValue(value); // Optimistic update.
      await repo.setUserPreference(prefName, value);
    },
    {
      onMutate: async (value: boolean) => {
        return { value };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['brava-feed']);
      },
      onError(error, variables, context) {
        if (context) {
          setValue(context.value);
        }
      },
    }
  );
  const data = useMemo(() => {
    return {
      ...mutation,
      value,
    };
  }, [value, mutation]);
  return data;
};

export const PreferencesPage = () => {
  const { birthdate_private, start_date_private } = useUserStore();
  const birthdateMutation = usePrefMutation('birthdate_private', birthdate_private);
  const startdateMutation = usePrefMutation('start_date_private', start_date_private);

  return (
    <ProfilePageLayout>
      <h1 className="text-xl font-medium">{_('preferences')}</h1>
      <Divider className="mt-3" />
      <div className="space-y-6">
        <div className="flex gap-4">
          <Toggle enabled={birthdateMutation.value} onChange={(e) => birthdateMutation.mutate(e)} label={_('hideMyBirthday')} />
        </div>
        <div className="flex gap-4">
          <Toggle
            enabled={startdateMutation.value}
            onChange={(e) => startdateMutation.mutate(e)}
            label={_('hideMyWorkAnniversary')}
          />
        </div>
      </div>
    </ProfilePageLayout>
  );
};
