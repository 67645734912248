const REASONS = [
  {
    id: 'thankYou',
    category: 'general',
  },
  {
    id: 'greatJob',
    category: 'general',
  },
  {
    id: 'welcome',
    category: 'general',
  },
  {
    id: 'celebrations',
    category: 'personalMilestones',
  },
  {
    id: 'personalDevelopment',
    category: 'personalMilestones',
  },
  {
    id: 'communityImpact',
    category: 'personalMilestones',
  },
  {
    id: 'wellnessAchievements',
    category: 'personalMilestones',
  },
  {
    id: 'workLifeBalanceSuccess',
    category: 'personalMilestones',
  },
  {
    id: 'teamPlayer',
    category: 'behavioralExcellence',
  },
  {
    id: 'mentor',
    category: 'behavioralExcellence',
  },
  {
    id: 'positiveAttitude',
    category: 'behavioralExcellence',
  },
  {
    id: 'innovator',
    category: 'behavioralExcellence',
  },
  {
    id: 'learner',
    category: 'behavioralExcellence',
  },
  {
    id: 'safetyLeader',
    category: 'behavioralExcellence',
  },
  {
    id: 'collaboration',
    category: 'teamBasedRecognition',
  },
  {
    id: 'projectSuccess',
    category: 'teamBasedRecognition',
  },
  {
    id: 'efficiencyBoost',
    category: 'teamBasedRecognition',
  },
  {
    id: 'topPerformer',
    category: 'perfBasedRecognition',
  },
  {
    id: 'crisisLeader',
    category: 'perfBasedRecognition',
  },
  {
    id: 'customerHero',
    category: 'perfBasedRecognition',
  },
  {
    id: 'costSaver',
    category: 'perfBasedRecognition',
  },
  {
    id: 'workAnniversary',
    category: 'calendarEvents',
  },
  {
    id: 'birthdays',
    category: 'calendarEvents',
  },
  {
    id: 'newTeammate',
    category: 'calendarEvents',
  },
];

export const getCategoryForReason = (reasonId: string): string => {
  return REASONS.find((r) => r.id === reasonId)?.category ?? 'general';
};

export const getColorForReason = (reasonId: string): [string, string | undefined] => {
  const cat = getCategoryForReason(reasonId);
  let bg = '#A7C7E7';
  let fg: undefined | string = undefined;

  switch (cat) {
    case 'personalMilestones':
      bg = '#A9D18E';
      break;
    case 'behavioralExcellence':
      bg = '#F4C842';
      break;
    case 'teamBasedRecognition':
      bg = '#76C7C0';
      break;
    case 'perfBasedRecognition':
      bg = '#F4A259';
      break;
    case 'calendarEvents':
      if (reasonId === 'workAnniversary') {
        bg = '#A377C6';
        fg = '#fff';
      } else if (reasonId === 'birthdays') {
        bg = '#FF9AA2';
      } else if (reasonId === 'newTeammate') {
        bg = '#FFC58B';
      } else {
        bg = '#A377C6';
      }
      break;
    case 'general':
    default:
      bg = '#A7C7E7';
      break;
  }

  return [bg, fg];
};
