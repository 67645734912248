import React from 'react';
import { classNames } from '../lib/utils';

export const Placeholder: React.FC<{ className?: string }> = ({ className }) => {
  return <div className={classNames('bg-grey-5', className)}></div>;
};

export const PlaceholderList: React.FC<{ count: number; children: React.ReactElement }> = ({ count = 3, children }) => {
  return <>{Array.from({ length: count }).map((_, i) => React.cloneElement(children, { key: i }))}</>;
};
