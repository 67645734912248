import { format, getUnixTime, isSameDay, isSameYear } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import { TFunction } from 'i18next';

export const diff = (laterDate: Date, earlierDate: Date) => {
  return differenceInSeconds(laterDate, earlierDate);
};

export function formatTimeAgoShortDuration(t: TFunction, date: Date) {
  const relTime = now() - getUnixTime(date);
  if (relTime < 60) {
    return t('common:reltime.justNow');
  } else if (relTime < 3600) {
    return t('common:reltime.minAgo', { n: Math.floor(relTime / 60) });
  } else if (isSameDay(date, new Date())) {
    return t('common:reltime.hourAgo', { n: Math.floor(relTime / 3600) });
  } else if (isSameYear(date, new Date())) {
    return format(date, 'd MMM');
  }
  return format(date, 'PP');
}

export const now = () => Math.floor(Date.now() / 1000);

export { addMinutes, isFuture, isPast };
