export type AccountStoreInformation = {
  info: {
    type: string;
    content: string;
  };
  rules?: { coins: number; label: string }[];
};

export type Branding = {
  icon_double: string | null;
  logo: string | null;
  tickets_icon: string | null;
  brava_currency: 'coins' | 'tickets' | null;
};

export type BravaFeedResult = {
  next: string | null;
  items: BravaFeedEntry[];
};

export type BravaFeedEntry = {
  id: string;
  author_id: string | null;
  author_name: string | null;
  message: string;
  recipient_id: string;
  recipient_name: string;
  brava_reason: string;
  brava_amount: number | null;
  brava_currency: 'coins' | 'tickets' | null;
  brava_total: number;
  comments: number;
  comments_top: BravaFeedComment[];
  likes: number;
  likedby_players: string[];
  private: boolean;
  created_on: number;
};

export type BravaFeedComment = {
  id: string;
  author_id: string;
  author_name: string;
  brava_amount: number | null;
  message: string;
  created_on: number;
};

export type ContributionEntry = {
  item_id: string;
  user_id: string;
  amount: number;
};

export type DrawEntry = {
  quantity: number;
  user_id: string;
};

export enum ItemType {
  Purchase = 'purchase',
  Raffle = 'raffle',
  Auction = 'auction',
  Contribution = 'contribution',
  Sweepstakes = 'sweepstakes',
}

export type Item = {
  id: string;
  type: ItemType;
  available_from: number;
  available_until: number;
  cost: number;
  description: string;
  name: string;
  purchased: number;
  quantity: number;
  image_url: string;
  thumbnail_url: string;
  auction: ItemAuctionData | null;
  raffle: ItemRaffleData | null;
  contribution: ItemContributionData | null;
  sweepstakes: ItemSweepstakesData | null;
  product: {
    id: string;
    name: string;
    order: number;
    variant: string;
  } | null;
};

export type ItemAuctionData = {
  bid_increment: number;
  handling_fee: number;
  bid: number | null;
  bidder: string | null;
  count: number;
};

export type ItemContributionData = {
  coins: number;
  goal: number;
  max: number;
  currency_symbol: string | null;
  currency_value: number | null;
};

export type ItemRaffleData = {
  tickets: DrawEntry[];
};

export type ItemSweepstakesData = {
  tickets: DrawEntry[];
};

export type LeaderboardEntry = {
  rank: number;
  value: number;
};

export type LeaderboardSectionEntry = LeaderboardEntry & {
  section: {
    anonymous?: boolean;
    id: string | null;
    name: string | null;
  };
};

export type LeaderboardUserEntry = LeaderboardEntry & {
  user: {
    anonymous?: boolean;
    id: string | null;
    firstname: string | null;
    lastname: string | null;
  };
};
export type Purchase = {
  id: string;
  item_id: string;
  item: PurchasedItem;
  made_on: number;
  redeemed_on: number | null;
  state: PurchaseState;
};

export type PurchasedItem = {
  id: string;
  name: string;
  image_url: string;
};

export enum PurchaseState {
  Made = 'made',
  Redeeming = 'redeeming',
  Redeemed = 'redeemed',
}

export type Redemption = {
  id: string;
  purchase_id: string;
  state: RedemptionState;
  type: string;
  url: string | null;
  item: RedemptionItem;
  user: RedemptionUser;
  options: null | RedemptionShippingOptions;
};

export type RedemptionShippingOptions = {
  additional_required_fields?: string[];
  allowed_countries?: string[];
  can_edit: boolean;
  footnote?: string;
  required_fields: string[];
  subtitle?: string;
  support_email?: string;
  url_only?: boolean; // Whether the redemption only works at the redemption URL.
  use_states: boolean; // Whether the state field should be collected.
};

export enum RedemptionState {
  Idle = 'idle',
  Pending = 'pending',
  Complete = 'complete',
}

// Sub-document of Redemption.
export type RedemptionItem = PurchasedItem;

// Sub-document of Redemption.
export type RedemptionUser = {
  id: string;
  firstname: string;
  lastname: string;
};

export type SectionInformation = {
  info: {
    type: string;
    content: string;
  };
};

export type Team = {
  id: string;
  account_id: string;
  name: string;
  brava_enabled: boolean;
  tickets_enabled: boolean;
};

export type TeamMarket = {
  categories: TeamMarketCategory[];
};

export type TeamMarketCategory = TeamMarketCategoryItems | TeamMarketCategoryOverflow;

type TeamMarketCategoryBase = {
  id: string;
  name: string;
};

export type TeamMarketCategoryItems = TeamMarketCategoryBase & {
  type: 'items';
  items: string[];
};

export type TeamMarketCategoryOverflow = TeamMarketCategoryBase & {
  type: 'overflow';
};

export type User = {
  id: string;
  account_id: string;
  team_id: string;
  firstname: string;
  lastname: string;
  email?: string;
  coins: number;
  coins_earned_lifetime?: number; // Did not always exist.
  tickets: number;

  birthdate_private: boolean;
  start_date_private: boolean;

  level: {
    number: number;
    badge_url: string;
    level_at: number;
    next_level_at: number | null;
  };

  // Shipping information, all fields are free form fields except for the country.
  shipping: {
    location_name?: string;
    location_id?: string;
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
    postcode?: string;
    state?: string;
    country?: string; // Expected to be a 2-letter country code.
  } | null;

  brava: {
    given: number;
    remaining: number; // Null is Infinity.
    allowance: number; // Null is Infinity.
  } | null;
};

export type UserTransaction = {
  amount: number;
  summary: string;
  lang: {
    string: string;
    args: {
      [index: string]: string;
    };
  };
  recorded_on: number;
};

export type WithBranding = {
  branding: Branding;
};

export type WithLeaderboards = {
  leaderboards: {
    id: 'individual' | 'svs';
    anonymous: boolean;
    enabled: boolean;
  }[];
};
